import React from 'react';
import styled from 'styled-components';
import { Accordion, Link, Text } from '@comicrelief/component-library';

const CustomAccordion = styled(Accordion)`
  background-color: ${({ theme }) => theme.color('blue_dark')};
  color: white;
  
  svg {
    fill: white;
  }
`;

const Title = styled(Text).attrs({
  color: 'white',
  family: 'Anton',
  tag: 'h4',
  size: 'xl',
  weight: 'normal',
  uppercase: true
})`
  margin-top: 3px !important; // This font doesn't quite look centered without being nudged a bit.
  margin-bottom: 0;
`;

const Paragraph = props => <Text tag="p" size="s" color="white" family="Montserrat" {...props} />;

const PrivacyNotice = () => (
  <CustomAccordion title={<Title>How will this information be used?</Title>}>
    <Paragraph weight="bold">
      Name, surname and email
    </Paragraph>
    <Paragraph>
      We need these to process your registration, to set up your JustGiving page, and to
      identify if you&apos;ve fundraised with us before.
    </Paragraph>
    <Paragraph weight="bold">Store ID</Paragraph>
    <Paragraph>
      We use this information to better understand which institutions raise money for us.
      Your
      details will be kept safe and never shared with other organisations.
    </Paragraph>
    <Paragraph>
      For more information, visit our
      {' '}
      <Link type="standard_white" target="blank" href="/privacy-notice/">
        Privacy Policy.
      </Link>
      {' '}
      If you are under 18, please make sure you have your parents&apos; permission before
      providing us with any personal details.
    </Paragraph>
  </CustomAccordion>
);

export default PrivacyNotice;
