import React, {
  useContext, useEffect, useRef
} from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  Select,
  spacing,
  Text,
  ErrorText,
  SimpleSchoolLookup
} from '@comicrelief/component-library';
import {
  INDUSTRIES,
  SCHOOL_JOB_TITLES,
  FUNDRAISING_GROUPS,
  SCHOOL_SEGMENTS,
  SCHOOL_FSU_SCHOOL_SEGMENTS,
  PUBLIC_SEGMENTS,
  FIELDS,
  ORG_SIZE,
  JUSTGIVING_STORIES
} from './constants';

import * as assets from './assets';

import Fieldset from '../../components/Fieldset/Fieldset';
import Grid from '../../components/Grid/Grid';
import ErrorGroupWrapper from './components/ErrorGroupWrapper';
import OptionCardTrigger from './components/OptionCardTrigger';
import ButtonAsLink from '../../components/ButtonAsLink/ButtonAsLink';
import PostcodeLookup from '../../components/PostcodeLookup/PostcodeLookup';
import {
  Button,
  SoftHider,
  SubmitContainer,
  ScrollWrapper
} from './FundraiserSignup.style';
import renderBody from '../../components/RichText/RichText';
import BackButton from './components/BackButton';
import ProgressBar from './components/ProgressBar';
import FundraiserSignupContext from './context/FundraiserSignupContext';

const FundraiserSignupStep2 = ({
  title,
  handler,
  description = 'Who are you fundraising with?',
  showTopError,
  showAddressInputs,
  setShowAddressInputs,
  setSelectedAddress,
  setSelectedSchool,
  hasDefaultGroup,
  options,
  campaignCode
}) => {
  const {
    step,
    register,
    isValidating,
    isSleeping,
    getError,
    watch,
    fundraisingGroup,
    setStep,
    getValues,
    setValue
  } = useContext(FundraiserSignupContext);

  const scrollAnchorRef = useRef(null);

  const { schoolsImage, workImage, publicImage } = options;

  const resetAddress = () => {
    [
      FIELDS.ORG_NAME,
      FIELDS.ORG_SIZE,
      FIELDS.INDUSTRY_TYPE,
      FIELDS.JOB,
      FIELDS.LINE1,
      FIELDS.LINE2,
      FIELDS.LINE3,
      FIELDS.TOWN,
      FIELDS.POSTCODE
    ].forEach((field) => {
      setValue(field, '');
    });
    setShowAddressInputs(false);
  };

  // Reset the organisation and address fields if the fundraising group is changed.
  const onGroupChange = () => {
    // As per the FIELDS.SEGMENT config in .utils, having to handle this here,
    // as schema.default('work') doesn't seem to want to work on FIRST select;
    // presumably something with render/state race conditions? Something to debug later.
    const currentGroup = getValues(FIELDS.GROUP);
    if (currentGroup === FUNDRAISING_GROUPS.WORK) {
      setValue(FIELDS.SEGMENT, currentGroup);
    } else {
      setValue(FIELDS.SEGMENT, '');
    }
    resetAddress();
    // Scroll to the fields after a brief pause, as to make the transition less jarring
    if (scrollAnchorRef && scrollAnchorRef.current) {
      setTimeout(() => {
        scrollAnchorRef.current.scrollIntoView({ behavior: 'smooth', inline: 'start' });
      }, 250);
    }
  };

  const schoolSelect = (school) => {
    setValue(FIELDS.ORG_NAME, school.name, { shouldValidate: true });
    setValue(FIELDS.LINE1, school.address_1, { shouldValidate: true });
    setValue(FIELDS.LINE2, school.address_2, { shouldValidate: true });
    setValue(FIELDS.LINE3, school.address_3, { shouldValidate: true });
    setValue(FIELDS.TOWN, school.town, { shouldValidate: true });
    setValue(FIELDS.POSTCODE, school.post_code, { shouldValidate: true });
    // Keep track of the last school that was selected; when the form is submitted, we will use the
    // school ID if the school's details (name or address) haven't been changed.
    setSelectedSchool(school);
    setSelectedAddress(null);
  };
  const addressSelect = (address) => {
    setValue(FIELDS.LINE1, address.Line1, { shouldValidate: true });
    setValue(FIELDS.LINE2, address.Line2, { shouldValidate: true });
    setValue(FIELDS.LINE3, address.Line3, { shouldValidate: true });
    setValue(FIELDS.TOWN, address.posttown, { shouldValidate: true });
    setValue(FIELDS.POSTCODE, address.postcode, { shouldValidate: true });
    // Keep track of the last address that was selected; when the form is submitted, we will use the
    // address ID if the address hasn't been changed.
    setSelectedAddress(address);
    setSelectedSchool(null);
  };

  const line1 = watch(FIELDS.LINE1);
  const line2 = watch(FIELDS.LINE2);
  const line3 = watch(FIELDS.LINE3);
  const town = watch(FIELDS.TOWN);
  const postcode = watch(FIELDS.POSTCODE);
  const orgName = watch(FIELDS.ORG_NAME);
  const fundraisingSegment = watch(FIELDS.SEGMENT);

  const isSchool = fundraisingGroup === FUNDRAISING_GROUPS.SCHOOL;
  const isWork = fundraisingGroup === FUNDRAISING_GROUPS.WORK;
  const isPublic = fundraisingGroup === FUNDRAISING_GROUPS.PUBLIC;
  const isClub = fundraisingSegment === PUBLIC_SEGMENTS.COMMUNITY_GROUP.value
  || fundraisingSegment === PUBLIC_SEGMENTS.RELIGIOUS_GROUP.value
  || fundraisingSegment === PUBLIC_SEGMENTS.SPORTS_CLUB.value;

  // Even though these groups have the same value across the 2 segment varities,
  // check both, just in case something should change in the future:
  const isPrimary = fundraisingSegment === SCHOOL_SEGMENTS.PRIMARY.value
  || fundraisingSegment === SCHOOL_FSU_SCHOOL_SEGMENTS.PRIMARY.value;

  const isSecondary = fundraisingSegment === SCHOOL_SEGMENTS.SECONDARY.value
  || fundraisingSegment === SCHOOL_FSU_SCHOOL_SEGMENTS.SECONDARY.value;

  const isCollege = fundraisingSegment === SCHOOL_SEGMENTS.COLLEGE.value;

  const isParentCarer = fundraisingSegment === SCHOOL_FSU_SCHOOL_SEGMENTS.PARENT_OR_CARER.value;

  const isYouthGroup = fundraisingSegment === SCHOOL_FSU_SCHOOL_SEGMENTS.YOUTH.value;

  const isSportsGroup = fundraisingSegment === SCHOOL_FSU_SCHOOL_SEGMENTS.SPORTS_CLUB.value;

  useEffect(() => {
    setValue(FIELDS.PAGE_STORY, JUSTGIVING_STORIES[campaignCode]);
  }, []);

  // Rather than hijacking the onChange event, which messes with render and state
  // events, just using the 'watch'ed input to trigger the same functionality:
  useEffect(() => {
    resetAddress();
  }, [fundraisingSegment]);

  const addressLegend = (isWork ? 'Tell us about your workplace' : 'Tell us more');

  // For 'school, nursery or youth group' signups, we only want to show the address lookup once
  // the user has selected a 'segment'. This is because the lookup they see depends upon the
  // segment that they select.
  const showStep2BelowSegment = !isSchool || !!fundraisingSegment;

  // Use the *Schools* Postcode lookup for these school types only:
  const useSchoolLookup = isSchool && (isPrimary || isSecondary || isCollege);

  return (
    <SoftHider show={step === 2} data-test="step-2">
      {/* See comments in step 2 continue button handler below about this stop-gap measure */}
      {showTopError && (
        <div css={{ marginBottom: spacing('lg') }}>
          <ErrorText>Please fix the errors below</ErrorText>
        </div>
      )}
      <BackButton onClick={() => setStep(1)} data-test="back-1" />
      <ProgressBar step={step} />

      {/* Group/audience fields */}
      <SoftHider show={!hasDefaultGroup} data-test="step-2--group">

        <Fieldset legend={title}>
          {description && renderBody(description)}
          <ErrorGroupWrapper errorMessage={getError(FIELDS.GROUP)}>
            <Grid columns={3} onChange={onGroupChange}>
              <OptionCardTrigger
                {...register(FIELDS.GROUP)}
                name={FIELDS.GROUP}
                label="School, nursery or youth group"
                value={FUNDRAISING_GROUPS.SCHOOL}
                image={schoolsImage || assets.schoolImg}
                data-test="schools"
              />
              <OptionCardTrigger
                {...register(FIELDS.GROUP)}
                name={FIELDS.GROUP}
                label="Workplace"
                value={FUNDRAISING_GROUPS.WORK}
                image={workImage || assets.workImg}
                data-test="work"
              />
              <OptionCardTrigger
                {...register(FIELDS.GROUP)}
                name={FIELDS.GROUP}
                label="Myself or a group"
                value={FUNDRAISING_GROUPS.PUBLIC}
                image={publicImage || assets.publicImg}
                data-test="public"
              />
            </Grid>

          </ErrorGroupWrapper>
        </Fieldset>
      </SoftHider>

      <ScrollWrapper ref={scrollAnchorRef}>
        <SoftHider show={fundraisingGroup}>
          <Fieldset legend={addressLegend}>

            {/* School fields */}
            {isSchool && (
            <>
              <Grid columns={2} mobileColumns={1} withMarginBottom>
                <Select
                  {...register(FIELDS.SEGMENT)}
                  name={FIELDS.SEGMENT}
                  id={FIELDS.SEGMENT}
                  label="Who are you?"
                  options={hasDefaultGroup
                    ? Object.values(SCHOOL_FSU_SCHOOL_SEGMENTS)
                    : Object.values(SCHOOL_SEGMENTS)}
                  errorMsg={getError(FIELDS.SEGMENT)}
                  // Don't hijack events on managed fields!!
                  // onChange={resetAddress}
                  data-test="establishment-type"
                />
              </Grid>
              {!isParentCarer && !isYouthGroup && !isSportsGroup && (
              <Grid columns={2} mobileColumns={1} withMarginBottom>
                <Select
                  {...register(FIELDS.JOB)}
                  name={FIELDS.JOB}
                  id={FIELDS.JOB}
                  label="What is your role?"
                  options={SCHOOL_JOB_TITLES.map(value => ({
                    value: value.value, displayValue: value.value
                  }))}
                  errorMsg={getError(FIELDS.JOB)}
                  data-test="job-title"
                />
              </Grid>
              )}
            </>
            )}

            {/* Public fields */}
            {isPublic && (
            <Grid columns={2} mobileColumns={1} withMarginBottom>
              <Select
                {...register(FIELDS.SEGMENT)}
                name={FIELDS.SEGMENT}
                id={FIELDS.SEGMENT}
                label="I'm fundraising with..."
                options={Object.values(PUBLIC_SEGMENTS)}
                errorMsg={getError(FIELDS.SEGMENT)}
                  // Don't hijack events on managed fields!!
                  // onChange={resetAddress}
                data-test="fundraising-with"
              />
            </Grid>
            )}
            {(isPublic && isClub) && (
            <Grid columns={2} mobileColumns={1} withMarginBottom>
              <Input
                {...register(FIELDS.ORG_NAME)}
                type="text"
                name={FIELDS.ORG_NAME}
                id={FIELDS.ORG_NAME}
                errorMsg={getError(FIELDS.ORG_NAME)}
                label="Group name"
                autoComplete="off"
                data-test="org-name"
                optional
              />
            </Grid>
            )}
            <SoftHider show={showStep2BelowSegment}>
              {!useSchoolLookup && !isPublic && !isParentCarer && (
              <Grid columns={2} mobileColumns={1} withMarginBottom>
                <Input
                  {...register(FIELDS.ORG_NAME)}
                  type="text"
                  name={FIELDS.ORG_NAME}
                  id={FIELDS.ORG_NAME}
                  errorMsg={getError(FIELDS.ORG_NAME)}
                  label={isWork ? 'Company name' : 'Organisation name'}
                  autoComplete="off"
                  data-test="org-name"
                  optional={isWork}
                />
              </Grid>
              )}

              {/* Work fields */}
              {isWork && (
              <>
                <Grid columns={2} mobileColumns={1} withMarginBottom>
                  <Select
                    {...register(FIELDS.INDUSTRY_TYPE)}
                    name={FIELDS.INDUSTRY_TYPE}
                    id={FIELDS.INDUSTRY_TYPE}
                    errorMsg={getError(FIELDS.INDUSTRY_TYPE)}
                    label="Industry type"
                    data-test="industry-type"
                    options={INDUSTRIES}
                    optional
                  />
                </Grid>
                <Grid columns={2} mobileColumns={1} withMarginBottom>
                  <Select
                    {...register(FIELDS.ORG_SIZE)}
                    name={FIELDS.ORG_SIZE}
                    id={FIELDS.ORG_SIZE}
                    errorMsg={getError(FIELDS.ORG_SIZE)}
                    label="Organisation size"
                    options={Object.values(ORG_SIZE)}
                    data-test="org-size"
                    optional
                  />
                </Grid>
              </>
              )}
              <Grid columns={2} mobileColumns={1} withMarginBottom>
                {useSchoolLookup ? (
                  <SimpleSchoolLookup onSelect={schoolSelect} data-test="school-lookup" />
                ) : (
                  <PostcodeLookup onSelect={addressSelect} data-test="postcode-lookup" />
                )}
              </Grid>

              {/* Address fields */}
              {!showAddressInputs && (
              <>
                {line1 && town && postcode && (
                  <div css={{ margin: `${spacing('l')} 0` }}>
                    {/* The school lookup returns a name value, the postcode one doesn't */}
                    {useSchoolLookup && <Text tag="p" size="m">{orgName}</Text>}
                    <Text tag="p" size="m">{line1}</Text>
                    {line2 && <Text tag="p" size="m">{line2}</Text>}
                    {line3 && <Text tag="p" size="m">{line3}</Text>}
                    <Text tag="p" size="m">{town}</Text>
                    <Text tag="p" size="m">{postcode}</Text>
                  </div>
                )}
                <Grid columns={2} mobileColumns={1}>
                  <ButtonAsLink onClick={() => setShowAddressInputs(true)}>
                    {(line1 && town && postcode)
                      ? 'Edit address'
                      : 'Or enter address manually'}
                  </ButtonAsLink>
                </Grid>
                {getError(FIELDS.LINE1) && (
                  <ErrorText weight="bold">
                    {isSchool && !isParentCarer
                      ? 'Please find your organisation or enter the details manually'
                      : 'Please find your address or enter the details manually'}
                  </ErrorText>
                )}
              </>
              )}
              <SoftHider show={showAddressInputs}>
                {useSchoolLookup && (
                <Grid columns={2} mobileColumns={1} withMarginBottom>
                  <Input
                    {...register(FIELDS.ORG_NAME)}
                    type="text"
                    name={FIELDS.ORG_NAME}
                    id={FIELDS.ORG_NAME}
                    errorMsg={getError(FIELDS.ORG_NAME)}
                    label="Organisation name"
                    autoComplete="off"
                  />
                </Grid>
                )}
                <Grid columns={2} mobileColumns={1} withMarginBottom>
                  <Input
                    {...register(FIELDS.LINE1)}
                    type="text"
                    name={FIELDS.LINE1}
                    id={FIELDS.LINE1}
                    label="Address line 1"
                    errorMsg={getError(FIELDS.LINE1)}
                    autoComplete="off"
                  />
                </Grid>
                <Grid columns={2} mobileColumns={1}>
                  <Input
                    {...register(FIELDS.LINE2)}
                    type="text"
                    name={FIELDS.LINE2}
                    id={FIELDS.LINE2}
                    label="Address line 2"
                    errorMsg={getError(FIELDS.LINE2)}
                    autoComplete="off"
                    optional
                  />
                  <Input
                    {...register(FIELDS.LINE3)}
                    type="text"
                    name={FIELDS.LINE3}
                    id={FIELDS.LINE3}
                    label="Address line 3"
                    errorMsg={getError(FIELDS.LINE3)}
                    autoComplete="off"
                    optional
                  />
                  <Input
                    {...register(FIELDS.TOWN)}
                    type="text"
                    name={FIELDS.TOWN}
                    id={FIELDS.TOWN}
                    label="Town/City"
                    errorMsg={getError(FIELDS.TOWN)}
                    autoComplete="off"
                  />
                  <Input
                    {...register(FIELDS.POSTCODE)}
                    type="text"
                    name={FIELDS.POSTCODE}
                    id={FIELDS.POSTCODE}
                    label="Postcode"
                    errorMsg={getError(FIELDS.POSTCODE)}
                    autoComplete="off"
                  />
                </Grid>
              </SoftHider>
            </SoftHider>
          </Fieldset>

          <SoftHider show={(showStep2BelowSegment)}>
            {step === 2 && (
            <SubmitContainer>
              <Button
                type="submit"
                loading={isValidating || isSleeping}
                disabled={isValidating || isSleeping}
                onClick={handler}
                data-test="submit-2"
              >
                Continue
              </Button>
            </SubmitContainer>
            )}
          </SoftHider>
        </SoftHider>
      </ScrollWrapper>
    </SoftHider>
  );
};

FundraiserSignupStep2.propTypes = {
  title: PropTypes.string.isRequired,
  handler: PropTypes.func.isRequired,
  description: PropTypes.shape({ raw: PropTypes.string.isRequired }),
  showTopError: PropTypes.bool.isRequired,
  showAddressInputs: PropTypes.bool.isRequired,
  setSelectedAddress: PropTypes.func.isRequired,
  setSelectedSchool: PropTypes.func.isRequired,
  setShowAddressInputs: PropTypes.func.isRequired,
  hasDefaultGroup: PropTypes.bool.isRequired,
  campaignCode: PropTypes.string.isRequired,
  options: PropTypes.shape({
    schoolsImage: PropTypes.string,
    workImage: PropTypes.string,
    publicImage: PropTypes.string
  }).isRequired
};

export default FundraiserSignupStep2;
