import styled from 'styled-components';
import {
  containers,
  spacing,
  zIndex,
  ButtonWithStates
} from '@comicrelief/component-library';

import * as assets from './assets';

// TO make the scroll less ugly and close to the top of the copy
export const ScrollWrapper = styled.div`
  padding-top: ${spacing('l')};
`;

export const Wrapper = styled.div`
  position: relative;
  background: ${({ theme }) => theme.color('grey_light')};
  display: flex;
  flex-direction: column;
  &:after {
    // Preload audience images
    content: url(${assets.publicImg}) url(${assets.schoolImg}) url(${assets.workImg}) none;
  }

  // Improve crummy layout where the image and copy are obscured
  div[class^='FundraiserSignupstyle__FormContainer'] {
    margin-top: -15px;
  }

  #marketing-preferences--marketing_prefs {
    padding: 0  ${spacing('l')} ${spacing('md')};
    background-color: ${({ theme }) => theme.color('red')};
    border-radius: ${spacing('md')};

    // Restyle error msg to suit new background
    label > div + span > span {
      background: ${({ theme }) => theme.color('black')};
      &:before {
        background-image: url(${assets.errorIcon});
      }
    }

    h3, p, span, label, a {
      color: white;
    }

    a {
      border-bottom-color: ${({ theme }) => theme.color('white')};;
    }
  }
`;

export const FormContainer = styled.div`
  ${zIndex('base')}
  margin: 0 auto;
  background-color: ${({ theme }) => theme.color('white')};
  padding: ${spacing('l')};
  width: 100%;
  max-width: calc(${containers.medium} - ${spacing('l')});
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  margin-bottom: ${spacing('m')};

  @media ${({ theme }) => theme.allBreakpoints('M')} {
    padding: ${spacing('xl')};
    border-radius: 1rem;
    margin-top: -4rem;
    margin-bottom: ${spacing('lg')};
  }

  input,
  select {
    max-width: 100%;
  }
`;

export const IntroTextWrapper = styled.div`
  margin-bottom: ${spacing('l')};

  @media ${({ theme }) => theme.allBreakpoints('M')} {
    width: 50%;
    margin-bottom: ${spacing('xl')};
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: ${spacing('l')} 0;
  background-color: ${({ theme }) => theme.color('grey_medium')};
`;

export const SubmitContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${spacing('xl')};
`;

export const Button = styled(ButtonWithStates)`
  padding-left: ${spacing('lg')};
  padding-right: ${spacing('lg')};
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
`;

// 'Soft' hides its children - i.e. sets 'display: none' on the container, keeping them in the dom
// (e.g. so that form inputs keep their values)
export const SoftHider = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
`;

export const DescriptionWrapper = styled.div`
  padding-bottom: ${spacing('lg')};
`;
