import argosLogo from './logos/argos.png';
import habitatLogo from './logos/habitat.png';
import nectarLogo from './logos/nectar.svg';
import sainsburysLogo from './logos/sainsburys.svg';
import sainsburysBankLogo from './logos/sainsburys-bank.png';
import tkMaxxLogo from './logos/tkmaxx.png';
import homesenseLogo from './logos/homesense.png';
import amazonLogo from './logos/amazon.png';
import hollandAndBarrettLogo from './logos/holland&Barrett.png';

import tkmaxxPromo from './promo-images/tkmaxx-promo--2024.jpg';
import tkmaxxPromoMob from './promo-images/tkmaxx-promo-2024--mobile.jpg';
import sainsPromo from './promo-images/Nourish_the_Nation_Stroke--desktop.jpg';
import sainsPromoMob from './promo-images/Nourish_the_Nation_Stroke--mobile.jpg';

// Used for both Amazon and H&B, as per requirements
import amazonHBPromo from './promo-images/amazon-promo-2024--desktop.jpg';
import amazonHBPromoMob from './promo-images/amazon-promo-2024--mobile.jpg';

export const SAINSBURYS_GROUP = 'sainsburys_group';
export const TK_MAXX = 'tkmaxx';
export const AMAZON = 'amazon';
export const HOLLAND_AND_BARRETT = 'holland_and_barrett';

const images = {
  [SAINSBURYS_GROUP]: {
    description: "Sainsbury's header image",
    fluid: {
      srcSet: `${sainsPromoMob} 256w, ${sainsPromoMob} 512w, ${sainsPromo} 1024w,${sainsPromo} 1440w`
    },
    fallback: { src: sainsPromo },
    placeHolder: { src: sainsPromo },
    seoImage: { src: sainsPromoMob }
  },
  [TK_MAXX]: {
    description: 'TK Maxx header image',
    fluid: {
      srcSet: `${tkmaxxPromoMob} 256w,\n${tkmaxxPromoMob} 512w,\n${tkmaxxPromo} 1024w,\n${tkmaxxPromo} 1440w`
    },
    fallback: { src: tkmaxxPromo },
    placeHolder: { src: tkmaxxPromo },
    seoImage: { src: tkmaxxPromoMob }
  },
  [AMAZON]: {
    description: 'Amazon header image',
    fluid: {
      srcSet: `${amazonHBPromoMob} 256w,\n${amazonHBPromoMob} 512w,\n${amazonHBPromo} 1024w,\n${amazonHBPromo} 1440w`
    },
    fallback: { src: amazonHBPromoMob },
    placeHolder: { src: amazonHBPromoMob },
    seoImage: { src: amazonHBPromoMob }
  },
  [HOLLAND_AND_BARRETT]: {
    description: 'Holland & Barrett header image',
    fluid: {
      srcSet: `${amazonHBPromoMob} 256w,\n${amazonHBPromoMob} 512w,\n${amazonHBPromo} 1024w,\n${amazonHBPromo} 1440w`
    },
    fallback: { src: amazonHBPromoMob },
    placeHolder: { src: amazonHBPromoMob },
    seoImage: { src: amazonHBPromoMob }
  }
};

export const DEFAULT_SUMMARY_COPY = {
  [SAINSBURYS_GROUP]: 'Thanks for taking the time to visit my JustGiving page. I’m fundraising to help Nourish The Nation with Comic Relief. By donating to Nourish The Nation, you’re helping Comic Relief to support people facing food poverty across the UK and in selected countries worldwide.',
  [TK_MAXX]: "Red Nose Day is back on Friday 15 March and I'm joining TK Maxx, Homesense and people across the nation to do something funny for money.\n \nTogether we can help Comic Relief tackle some of the urgent problems facing children and young people globally, including poverty, conflict, and climate change.\n \nHowever much you can spare, your donation will help change the lives of children and young people here in the UK and around the world.",
  [AMAZON]: 'Red Nose Day is back on Friday 15 March and I’m joining Amazon and people across the nation to do something funny for money.\n \nTogether we can help Comic Relief put food on plates and roofs over heads, keep little ones safe and help support families affected by conflict and climate change.\n \nWhatever you can give could make all the difference. ',
  [HOLLAND_AND_BARRETT]: 'Red Nose Day is back on Friday 15 March and I’m joining Holland & Barrett and people across the nation to do something funny for money.\n \nTogether we can help Comic Relief put food on plates and roofs over heads, keep little ones safe and help support families affected by conflict and climate change.\n \nWhatever you can give could make all the difference.'
};

export const THANKYOU_COPY = {
  [SAINSBURYS_GROUP]: 'Thank you for registering to fundraise with Sainsbury’s Group this Red Nose Day. The money you raise will make a huge difference to children and young people’s lives here in the UK and around the world.',
  [TK_MAXX]: 'Thank you for registering to fundraise with TK Maxx and Homesense this Red Nose Day. The money you raise will make a huge difference to children and young people’s lives here in the UK and around the world.',
  [AMAZON]: 'Thank you for registering to fundraise with Amazon this Red Nose Day. The money you raise will make a huge difference to children and young people’s lives here in the UK and around the world.',
  [HOLLAND_AND_BARRETT]: 'Thank you for registering to fundraise with Holland & Barrett this Red Nose Day. The money you raise will make a huge difference to children and young people’s lives here in the UK and around the world.'
};

export const PARTNERS = {
  [SAINSBURYS_GROUP]: {
    label: 'Sainsbury\'s',
    companies: [
      {
        name: 'sainsburys',
        label: 'Sainsbury\'s',
        logo: { src: sainsburysLogo }
      },
      {
        name: 'argos',
        label: 'Argos',
        logo: {
          src: argosLogo
        }
      },
      {
        name: 'habitat',
        label: 'Habitat',
        logo: {
          src: habitatLogo,
          style: {
            maxWidth: '210px'
          }
        }
      },
      {
        name: 'sainsburys_bank',
        label: 'Sainsbury\'s Bank',
        logo: {
          src: sainsburysBankLogo,
          style: {
            maxWidth: '274px'
          }
        }
      },
      {
        name: 'nectar',
        label: 'Nectar',
        logo: {
          src: nectarLogo,
          style: {
            maxHeight: '108px'
          }
        }
      }
    ]
  },
  [TK_MAXX]: {
    label: 'TK Maxx',
    companies: [
      {
        name: 'tkmaxx',
        label: 'TK Maxx',
        logo: {
          src: tkMaxxLogo,
          style: {
            maxWidth: '200px',
            maxHeight: '200px'
          }
        }
      },
      {
        name: 'homesense',
        label: 'Homesense',
        logo: {
          src: homesenseLogo,
          style: {
            maxWidth: '200px',
            maxHeight: '200px'
          }
        }
      }
    ]
  },
  [AMAZON]: {
    label: 'Amazon',
    store_label: 'Your Org',
    store_select: 'Select your department',
    store_id: 'Your Team',
    store_id_error: 'Please enter your team',
    companies: [
      {
        exclude_from_dropdown: true,
        name: 'amazon',
        label: 'Amazon',
        logo: {
          src: amazonLogo
        }
      },
      { name: 'amazon_cf_eu21', label: 'CF EU21 (Fulfilment Centres)' },
      { name: 'amazon_cf_eu22', label: 'CF EU22 (Fulfilment Centres)' },
      { name: 'amazon_cf_eu23', label: 'CF EU23 (Fulfilment Centres)' },
      { name: 'amazon_amzl_northwest', label: 'AMZL (Delivery Stations) Northwest, Scotland and Ireland' },
      { name: 'amazon_amzl_east', label: 'AMZL (Delivery Stations) East' },
      { name: 'amazon_amzl_west', label: 'AMZL (Delivery Stations) West' },
      { name: 'amazon_amzl_southeast', label: 'AMZL (Delivery Stations) London and South East' },
      { name: 'amazon_ats', label: 'ATS' },
      { name: 'amazon_retail', label: 'Retail' },
      { name: 'amazon_3p', label: '3P' },
      { name: 'amazon_ads', label: 'Ads' },
      { name: 'amazon_aws', label: 'AWS' },
      { name: 'amazon_prime_video_studios_imdb', label: 'Prime Video, Studios and IMDb' },
      { name: 'amazon_music_twitch_games_audible', label: 'Music, Twitch, Games and Audible' },
      { name: 'amazon_devices', label: 'Devices, Alexa and Ring' },
      { name: 'amazon_fresh', label: 'Fresh and Whole Foods Market' },
      { name: 'amazon_gcci', label: 'Global Communications and Community Impact (GCCI)' },
      { name: 'amazon_finance_accounting_legal', label: 'Finance, accounting and legal' },
      { name: 'amazon_customer_service', label: 'Customer Service' },
      { name: 'amazon_sustainability', label: 'Sustainability' },
      { name: 'amazon_pp', label: 'PP' },
      { name: 'amazon_other', label: 'Other' }
    ]
  },
  [HOLLAND_AND_BARRETT]: {
    label: 'Holland & Barrett',
    store_label: 'Your Business Area',
    store_select: 'Select your Division/Department',
    store_id: 'Store Number or Business Area Department',
    store_id_error: 'Please enter your Store Number or Business Area Department',
    companies: [
      {
        name: 'holland_and_barrett',
        label: 'Holland & Barrett',
        exclude_from_dropdown: true,
        logo: {
          src: hollandAndBarrettLogo,
          style: {
            maxWidth: '200px',
            maxHeight: '200px'
          }
        }
      },
      { name: 'h&b_division_1', label: 'Division 1' },
      { name: 'h&b_division_2', label: 'Division 2' },
      { name: 'h&b_division_3', label: 'Division 3' },
      { name: 'h&b_division_4', label: 'Division 4' },
      { name: 'h&b_business_science', label: 'Business & Science' },
      { name: 'h&b_digital_customer_marketing', label: 'Digital, Customer & Marketing' },
      { name: 'h&b_commercial', label: 'Commercial' },
      { name: 'h&b_finance_legal', label: 'Finance & Legal' },
      { name: 'h&b_fulfilment', label: 'Fulfilment' },
      { name: 'h&b_hr', label: 'HR' },
      { name: 'h&b_product_tech', label: 'Product & Tech' },
      { name: 'h&b_retail_property', label: 'Retail & Property' }
    ]
  }
};

export const singleMessageProps = {
  [TK_MAXX]: {
    title: 'CSU TK Maxx 2024',
    id: 'doesnt-matter',
    copyFirst: false,
    centredText: false,
    fullImage: false,
    verticalHeight: false,
    image: images[TK_MAXX],
    backgroundColour: 'red',
    text: {
      raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"Register to fundraise","nodeType":"text"}],"nodeType":"heading-2"},{"data":{},"content":[{"data":{},"marks":[],"value":"Red Nose Day is back on Friday 15 March 2024 and we’d love you to do something funny for money.","nodeType":"text"}],"nodeType":"paragraph"},{"data":{},"content":[{"data":{},"marks":[],"value":"","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}'
    }
  },
  [SAINSBURYS_GROUP]: {
    title: 'CSU Sainsburys 2024',
    id: 'doesnt-matter',
    copyFirst: false,
    centredText: false,
    fullImage: false,
    verticalHeight: false,
    image: images[SAINSBURYS_GROUP],
    backgroundColour: 'red',
    text: {
      raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"Register to fundraise","nodeType":"text"}],"nodeType":"heading-2"},{"data":{},"content":[{"data":{},"marks":[],"value":"Help us Nourish The Nation with Comic Relief","nodeType":"text"}],"nodeType":"paragraph"},{"data":{},"content":[{"data":{},"marks":[],"value":"","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}'
    }
  },
  [AMAZON]: {
    title: 'CSU Amazon 2024',
    id: 'doesnt-matter',
    copyFirst: false,
    centredText: false,
    fullImage: false,
    verticalHeight: false,
    image: images[AMAZON],
    backgroundColour: 'red',
    text: {
      raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"Register to fundraise","nodeType":"text"}],"nodeType":"heading-2"},{"data":{},"content":[{"data":{},"marks":[],"value":"Red Nose Day is back on Friday 15 March 2024 and we’d love you to do something funny for money.","nodeType":"text"}],"nodeType":"paragraph"},{"data":{},"content":[{"data":{},"marks":[],"value":"","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}'
    }
  },
  [HOLLAND_AND_BARRETT]: {
    title: 'CSU Holland & Barrett 2024',
    id: 'doesnt-matter',
    copyFirst: false,
    centredText: false,
    fullImage: false,
    verticalHeight: false,
    image: images[HOLLAND_AND_BARRETT],
    backgroundColour: 'Transparent',
    text: {
      raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"Register to fundraise","nodeType":"text"}],"nodeType":"heading-2"},{"data":{},"content":[{"data":{},"marks":[],"value":"Red Nose Day is back on Friday 15 March 2024 and we’d love you to do something funny for money","nodeType":"text"}],"nodeType":"paragraph"},{"data":{},"content":[{"data":{},"marks":[],"value":"","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}'
    }
  }
};
