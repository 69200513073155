import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { snakeCase } from 'lodash';
import {
  Text,
  CardDs,
  spacing,
  hideVisually,
  containers
} from '@comicrelief/component-library';

import handlePadding from '@comicrelief/component-library/src/utils/_utils';

import renderRichText from '../RichText/RichText';
import CtaLinkIcon from '../CtaLinkIcon/CtaLinkIcon';
import isInternalUrl from '../../utils/isInternalUrl';

const Wrapper = styled.div`
  background: ${({ theme, bgColour }) => theme.color(bgColour)};
  padding: ${spacing('md')};
  text-align: center;
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.allBreakpoints('M')} {
    padding: 3rem ${spacing('m')};
  }

  @media ${({ theme }) => theme.allBreakpoints('L')} {
    // We only want to apply this padding tweak to ROW layouts, although it looks like someone got the
    // logic backwards when this work was done, so having to test for the opposite:
    ${({ paddingOption, columnsOnMd }) => columnsOnMd === true && handlePadding(paddingOption)};
  }

  @media ${({ theme }) => theme.allBreakpoints('XL')} {
    // LG breakpoint is always rows, so we can add this without an additional check:
    ${({ paddingOption }) => handlePadding(paddingOption)};
  }
`;

const Grid = styled.div`
  display: flex;
  width: 100%;
  max-width: ${containers.medium};
  margin: 0 auto;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  @media ${({ theme }) => theme.allBreakpoints('XL')} {
    flex-direction: row;
  }
  ${({ columnsOnMd }) => columnsOnMd
    && css`
      @media ${({ theme }) => theme.allBreakpoints('L')} {
        flex-direction: row;
      }
    `}
`;

const Items = styled.div`
  margin: 0 0 ${spacing('xl')};
  width: 100%;
  text-align: left;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: calc(100% / 2 - ${spacing('lg')});

  @media ${({ theme }) => theme.allBreakpoints('XL')} {
    margin: 0 ${spacing('m')} 5rem;
    flex-basis: ${({ layout }) => {
    switch (layout) {
      case '3 columns':
        return `calc(100% / 3 - ${spacing('lg')}); flex-grow: 0`;
      default:
        return null;
    }
  }};
  }
  /* styles responding to a radio button that chooses column layout on MD breakpoint */
  ${({ columnsOnMd }) => columnsOnMd && css`
    @media ${({ theme }) => theme.allBreakpoints('L')} {
      margin: 0 ${spacing('m')} 5rem;
      flex-basis: ${({ layout }) => {
    switch (layout) {
      case '3 columns':
        return `calc(100% / 3 - ${spacing('lg')}); flex-grow: 0`;
      default:
        return null;
    }
  }};
        > div {
          flex-direction: column;
        }
        > div > *:first-child {
          width: 100%;
          height: auto;
          margin: 0 -1.5rem 0 1.5rem;
        }
        > div > a:first-child {
          @media ${({ theme }) => theme.allBreakpoints('XL')} {
            margin: 0;
          }
        }
        > div > div:nth-child(2) {
          @media ${({ theme }) => theme.allBreakpoints('L')} {
            width: 100%;
            height: 100%;
            margin: calc(-2 * 1.5rem) 0 0 0;
          }
        }
        > div > div:nth-child(3) {
          @media ${({ theme }) => theme.allBreakpoints('L')} {
            bottom: -2.2rem;
          }
        }
      }
    `}

    /* fix for image layout bug when there is no link/CTA present */
    > div > div:first-child {
      @media ${({ theme }) => theme.allBreakpoints('XL')} {
        width: 100%;
      }
    }
`;

const HideText = styled(Text)`
  ${hideVisually};
`;

const CardsDs = ({ data }) => {
  const {
    title, cards, backgroundColour: bgCards, layout, columnsOnMd, paddingOptions
  } = data;

  const cardsBackground = snakeCase(bgCards || 'white');
  const paddingOption = paddingOptions ? snakeCase(paddingOptions) : null;

  return (
    <Wrapper bgColour={cardsBackground} paddingOption={paddingOption} columnsOnMd={columnsOnMd}>
      <HideText tag="h2">{title}</HideText>
      <Grid columnsOnMd={columnsOnMd}>
        {cards && cards.map((card) => {
          const {
            body: cardBody,
            id,
            image,
            backgroundColour,
            link,
            linkLabel
          } = card;

          const fallback = image?.fallback?.src;
          const imageLow = image?.placeHolder?.src;
          const images = image?.fluid?.srcSet;
          const bgColour = snakeCase(backgroundColour || 'white');
          const description = image?.description ? image.description : '';
          const isInternalLink = link && isInternalUrl(link);
          const target = isInternalLink ? 'self' : 'blank';

          return (
            <Items key={id} layout={layout} columnsOnMd={columnsOnMd}>
              <CardDs
                target={target}
                link={link}
                linkLabel={linkLabel}
                imageLow={imageLow}
                image={fallback}
                images={images}
                backgroundColor={bgColour}
                imageAltText={description}
                icon={<CtaLinkIcon url={link} label={linkLabel} />}
              >
                {cardBody && renderRichText(cardBody, bgColour, 'card')}
              </CardDs>
            </Items>
          );
        })}
      </Grid>
    </Wrapper>
  );
};

CardsDs.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    cards: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        body: PropTypes.object, // eslint-disable-line react/forbid-prop-types
        image: PropTypes.object, // eslint-disable-line react/forbid-prop-types
        backgroundColour: PropTypes.string,
        link: PropTypes.string,
        linkLabel: PropTypes.string
      })
    ),
    backgroundColour: PropTypes.string,
    layout: PropTypes.string,
    columnsOnMd: PropTypes.bool,
    paddingOptions: PropTypes.string
  }).isRequired
};

export default CardsDs;
