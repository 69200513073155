import React from 'react';
import {
  Link,
  Text,
  Checkbox,
  spacing
} from '@comicrelief/component-library';
import PropTypes from 'prop-types';
import { FIELDS } from '../constants';

const LongLabel = () => (
  <Text tag="p">
    I confirm I am over 18 and agree to Comic Relief&apos;s
    {' '}
    <Link target="blank" href="/terms-of-use/">Terms of Use</Link>
    {' '}
    including the Fundraising
    {' '}
    <Link target="blank" href="/terms-of-use/#fundraising-dos-and-donts-anchor/">Dos & Don&apos;ts</Link>
    .
    <br />
    For further information on how Comic Relief will use your information please read our
    {' '}
    <Link target="blank" href="/privacy-notice/">Privacy Policy</Link>
    .
  </Text>
);

const TermsAndConditions = ({ register }) => (
  <div style={{ marginTop: spacing('lg') }}>
    <Checkbox
      {...register(FIELDS.OVER_EIGHTEEN)}
      name={FIELDS.OVER_EIGHTEEN}
      value={FIELDS.OVER_EIGHTEEN}
      id={FIELDS.OVER_EIGHTEEN}
    >
      <LongLabel />
    </Checkbox>
  </div>
);

export default TermsAndConditions;

TermsAndConditions.propTypes = {
  register: PropTypes.func.isRequired
};
