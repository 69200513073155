import React from 'react';
import PropTypes from 'prop-types';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { spacing, Text } from '@comicrelief/component-library';

const IsRegistering = ({ manualRedirectUrl = undefined, thankYouCopy }) => (
  <div css={{
    marginBottom: spacing('xl'),
    textAlign: 'center'
  }}
  >
    <div css={{ marginBottom: spacing('m') }}>
      <Text tag="p" size="l" weight="bold">{thankYouCopy}</Text>
      <Text tag="p" size="m">
        This could take up to 30 seconds or so, please don&apos;t refresh or leave the page.
      </Text>
      <Text tag="p" size="m">
        You will then be taken to JustGiving to continue setting up your page.
      </Text>
      {manualRedirectUrl && (
        <Text tag="p" size="m">
          Success! You can now
          {' '}
          <a href={manualRedirectUrl}>
            follow this link if you are not redirected automatically
          </a>
        </Text>
      )}
    </div>
    <ScaleLoader height={32} width={4} loading />
  </div>
);

IsRegistering.propTypes = {
  manualRedirectUrl: PropTypes.string,
  thankYouCopy: PropTypes.string.isRequired
};

export default IsRegistering;
