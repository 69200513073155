import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { snakeCase } from 'lodash';
import {
  SingleMessageDs as SingleMessage, spacing, containers
} from '@comicrelief/component-library';

import renderRichText from '../RichText/RichText';
import CtaLinkIcon from '../CtaLinkIcon/CtaLinkIcon';
import isInternalUrl from '../../utils/isInternalUrl';

const Wrapper = styled.div`
  width: 100%;
  max-width: ${containers.medium};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background: ${({ theme, bgColour }) => theme.color(bgColour)};

  padding: ${spacing('md')};
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    padding: 3rem ${spacing('m')};
  }
`;

const BodyWrapper = styled.div`
  margin-top: ${spacing('md')};
`;

const SingleMessageDs = ({ data }) => {
  const imageLow = data.image ? data.image.fallback?.src : null;
  const images = data.image ? data.image.fluid?.srcSet : null;
  const imagesDesc = data.image ? data.image.description : null;
  const bgColour = snakeCase(data.backgroundColour);

  const {
    label, ctaLabel, ctaLink, imageLeft, body, youTubeId
  } = data;

  let target;

  if (ctaLink) {
    target = isInternalUrl(ctaLink) ? 'self' : 'blank';
  }

  const colour = data.ctaBackgroundColour ? snakeCase(data.ctaBackgroundColour) : 'red';
  return (
    <Wrapper>
      <SingleMessage
        target={target}
        link={ctaLink}
        linkLabel={ctaLabel}
        linkIcon={<CtaLinkIcon url={ctaLink} label={ctaLabel} />}
        ctaBgColor={colour}
        backgroundColor={bgColour}
        imageLow={imageLow}
        images={images}
        imageLeft={imageLeft}
        imageAltText={imagesDesc}
        subtitle={label}
        height="100%"
        youTubeId={youTubeId}
      >
        <BodyWrapper>{renderRichText(body, 'white', 'SingleMessageDs')}</BodyWrapper>
      </SingleMessage>
    </Wrapper>
  );
};

SingleMessageDs.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string.isRequired,
    ctaLabel: PropTypes.string.isRequired,
    ctaLink: PropTypes.string,
    ctaBackgroundColour: PropTypes.string,
    imageLeft: PropTypes.bool.isRequired,
    youTubeId: PropTypes.string,
    body: PropTypes.shape(),
    image: PropTypes.shape({
      fallback: PropTypes.shape().isRequired,
      fluid: PropTypes.shape().isRequired,
      description: PropTypes.string.isRequired
    }).isRequired,
    backgroundColour: PropTypes.string
  }).isRequired
};

SingleMessageDs.defaultProp = {
  youTubeId: null,
  ctaLink: null,
  backgroundColour: null
};

export default SingleMessageDs;
