import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  EmailSignUp as EmailSignUpBase,
  buildEsuValidationSchema,
  RichText
} from '@comicrelief/component-library';
import axios from 'axios';
import schemaOverride from './config';

const EmailSignUp = ({
  title = 'Stay in the know!',
  topCopy = (
    <RichText
      markup={"<p>Get regular email updates and info on what we're up to!</p>"}
    />
  ),
  successCopy = (
    <RichText markup="<p>Thanks! Your first email will be with you shortly</p>" />
  ),
  privacyCopy = (
    <RichText
      markup={
        '<p>Our <a class="link link--white inline" href="/privacy-notice">Privacy Policy</a> describes how we handle and protect your information.<br><br>If you are under 18, please make sure you have your parents’ permission before providing us with any personal details.</p>'
      }
    />
  ),
  databaseTag,
  campaignCode,
  backgroundColour = 'transparent',
  buttonColour = 'red',
  columnLayout = false,
  isHeader = false
}) => {
  const validationSchema = buildEsuValidationSchema(schemaOverride);
  const formMethods = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema)
  });
  const { handleSubmit, setError } = formMethods;

  async function handleSubscribe(formData) {
    const payload = {
      ...formData,
      transSource: databaseTag,
      campaign: campaignCode,
      transSourceURL: window.location.href
    };

    if (!window.dataLayer) window.dataLayer = [];

    try {
      await axios.post(
        `${process.env.GATSBY_CONTACT_SERVICE_BASE_URL}/email-signup`,
        payload
      );

      window.dataLayer.push({
        event: isHeader ? 'cr_esu_signup_nav' : 'cr_esu_signup_body'
      });
    } catch ({ response: { data } }) {
      // `contact-service` validates the data on the back-end
      // and returns an object of validation_errors
      // This selects and displays the first of those
      const errors = data.validation_errors;
      if (errors !== undefined) {
        const error = Object.values(errors)[0];
        setError('formError', {
          message: error
        });
      } else {
        // Otherwise fallback to a default error message
        setError('formError', {
          message: 'Sorry, there was a problem processing your subscription.'
        });
      }
    }
  }

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(handleSubscribe)} noValidate>
        <EmailSignUpBase
          title={title}
          topCopy={topCopy}
          successCopy={successCopy}
          privacyCopy={privacyCopy}
          backgroundColour={backgroundColour}
          buttonColour={buttonColour}
          formContext={formMethods}
          columnLayout={columnLayout}
        />
      </form>
    </FormProvider>
  );
};

EmailSignUp.propTypes = PropTypes.shape({
  title: PropTypes.string,
  topCopy: PropTypes.string,
  successCopy: PropTypes.string,
  privacyCopy: PropTypes.string,
  backgroundColour: PropTypes.string,
  buttonColour: PropTypes.string,
  columnLayout: PropTypes.bool,
  databaseTag: PropTypes.string.isRequired,
  campaignCode: PropTypes.string.isRequired,
  isHeader: PropTypes.bool.isRequired
}).isRequired;

export default EmailSignUp;
