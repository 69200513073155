import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { spacing } from '@comicrelief/component-library';

const LogoImage = styled.img`
  height: auto;
  width: 100px;
  max-width: 100%;
  padding: ${spacing('sm')};
  object-fit: contain;
  object-position: right;

  @media ${({ theme }) => theme.allBreakpoints('M')} {
    max-width: 234px;
    max-height: 68px;
    width: 100%;
    padding: 0;
  }
`;

const LogoItem = styled.li`
  display: block;
  padding: 0;
  text-align: center;
  max-width: 50%;
  min-width: 33%;
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    max-width: 100%;
    min-width: 0;
    margin-bottom: ${spacing('lg')};
    text-align: right;
  }
`;

const LogoGroup = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0 0 ${spacing('m')};

  @media ${({ theme }) => theme.allBreakpoints('M')} {
    display: block;
    margin-bottom: 0;
    padding: ${spacing('l')};
    transform: scale(0.7);
    transform-origin: top right;
  }
`;

const Logos = ({ companies }) => (
  <LogoGroup>
    {companies.map(({ name, label, logo }) => (
      // Not EVERY company has a logo, so:
      logo && (
        <LogoItem key={`logo-${name}`}>
          <LogoImage alt={label} {...logo} />
        </LogoItem>
      )
    ))}
  </LogoGroup>
);

Logos.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    logo: PropTypes.shape({
      src: PropTypes.string.isRequired,
      style: PropTypes.shape
    })
  })).isRequired
};

export default Logos;
