import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as getUuid } from 'uuid';
import { useForm } from 'react-hook-form';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import {
  ErrorText,
  Input,
  Link,
  Select,
  TextArea,
  spacing,
  ButtonWithStates,
  Confetti
} from '@comicrelief/component-library';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from 'axios';

import {
  FormContainer,
  SubmitContainer,
  Wrapper
} from '../FundraiserSignup/FundraiserSignup.style';
import {
  ErrorMessageContainer,
  FieldHint,
  TwoColumnsSwappedOnDesktop
} from './FsuCorporatePartner.style';
import withRecaptcha from '../../utils/withRecaptcha';
import Layout from '../../components/Layout/Layout';
import SEO from '../../components/SEO/SEO';
import Grid from '../../components/Grid/Grid';
import SingleMessage from '../../components/SingleMessage/SingleMessage';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import Fieldset from '../../components/Fieldset/Fieldset';
import Logos from './components/Logos';
import { FIELDS } from '../FundraiserSignup/constants';
import {
  PARTNERS,
  DEFAULT_SUMMARY_COPY,
  THANKYOU_COPY,
  singleMessageProps
} from './partners';
import { getRules } from '../FundraiserSignup/utils';
import GiftAid from './components/GiftAid';
import Target from '../FundraiserSignup/components/Target';
import PrivacyNotice from './components/PrivacyNotice';
import IsRegistering from './components/IsRegistering';
import ProgressBar from './components/ProgressBar';

const fatalErrorMessage = (
  <ErrorMessageContainer>
    <ErrorText weight="bold">
      Sorry, something went wrong. Please refresh the page and try again, or
      contact
      {' '}
      <Link href="mailto:fundraising@comicrelief.com">
        fundraising@comicrelief.com
      </Link>
      {' '}
      if the problem persists.
    </ErrorText>
  </ErrorMessageContainer>
);

/**
 * Pushes to the Google Tag Manager dataLayer, so the data team can track corporate partner
 * signups, and then redirects to the JustGiving claim URL.
 *
 * (These two activities are combined as we need to make sure the tracking stuff has executed
 * before the redirect happens.)
 *
 * @param {string} uuid
 * @param {string} parentCompanyName
 * @param {string} companyName
 * @param {string} claimUrl
 */
const trackSignupAndRedirect = (
  uuid,
  parentCompanyName,
  companyName,
  claimUrl
) => {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }

  const redirect = () => {
    window.location.href = claimUrl;
  };

  const dataLayerItem = {
    event: 'cr-partners-reg',
    action: `cr-partners-reg-${parentCompanyName}-${companyName}`,
    label: 'complete',
    // Note: This is not a reference to the uuid column in the fundraiser_signup_ tables in Athena.
    // It refers to the uuid in the corporate_partner_ tables.
    // However, data team wanted it to have the same key as the fundraiser signups tracking event.
    fundraiser_signup_uuid: uuid,
    // Using GTM's eventCallback should mean the redirect only happens after GTM has finished
    // processing this action.
    eventCallback: redirect
  };

  window.dataLayer.push(dataLayerItem);

  // This is a fallback to make sure the redirect takes place,
  // even if something goes wrong with GTM.
  setTimeout(redirect, 2000);
};

const registerPage = (data, reCaptchaToken) => axios
  .post(
    `${process.env.GATSBY_CONTACT_SERVICE_BASE_URL}/partner/register`,
    data,
    {
      // It can take a while
      timeout: 20000,
      params: {
        recaptcha_token: reCaptchaToken
      }
    }
  )
  .then(res => res.data.data);

const RECAPTCHA_ACTION = 'partner/register';

/**
 * Corporate partner page
 *
 * @param pageContext
 * @returns {JSX.Element}
 * @constructor
 */
const FsuCorporatePartner = ({
  pageContext: { partner: partnerName }
}) => {
  const partner = PARTNERS[partnerName];
  const defaultRules = getRules();

  /* eslint-disable no-template-curly-in-string */
  const methods = useForm({
    resolver: yupResolver(

      yup.object().shape(
        {
          [FIELDS.FIRST_NAME]: defaultRules[FIELDS.FIRST_NAME],
          [FIELDS.LAST_NAME]: defaultRules[FIELDS.LAST_NAME],
          [FIELDS.EMAIL]: defaultRules[FIELDS.EMAIL],
          [FIELDS.CONFIRM_EMAIL]: defaultRules[FIELDS.CONFIRM_EMAIL],
          company: yup.string().required('Please select your company'),
          store_id: yup
            .string()
            .max(100, 'Please enter a maximum of ${max} characters')
            .required(partner.store_id_error || 'Please enter your store ID or department'),
          page_title: yup
            .string()
            .trim()
            .matches(
              /^[a-zA-Z0-9!?$£@:;'",.-\s]*$/,
              'Please enter only letters, numbers and any of these special characters:  ! ? $ £ @ : ; \' " , . -'
            )
            .max(150, 'Please enter a maximum of ${max} characters')
            .required('Please enter a name for your page')
            .min(3, 'Please enter at least ${min} characters'),
          page_story: yup
            .string()
            .max(1000, 'Please enter a maximum of ${max} characters')
            .required('Please enter a summary for your page'),
          accept_gift_aid: yup.boolean().required(),
          [FIELDS.TARGET_TEXT]: defaultRules[FIELDS.TARGET_TEXT],
          [FIELDS.TARGET_POUNDS]: defaultRules[FIELDS.TARGET_POUNDS]
        }
      )
    ),
    mode: 'onChange',
    shouldFocusError: true,
    shouldUnregister: true
  });

  const { executeRecaptcha } = useGoogleReCaptcha();
  const [hasFatalError, setHasFatalError] = useState(false);
  const scrollAnchorRef = useRef(null);
  const [isRegistering, setIsRegistering] = useState(false);
  const [manualRedirectUrl, setManualRedirectUrl] = useState(undefined);

  const companyOptions = partner.companies.filter(
    company => !company.exclude_from_dropdown
  ).map(company => ({
    value: company.name,
    displayValue: company.label
  }));

  const {
    handleSubmit, register, formState: { errors }, watch, setValue
  } = methods;

  const doScroll = () => {
    if (scrollAnchorRef && scrollAnchorRef.current) {
      scrollAnchorRef.current.scrollIntoView();
    }
  };

  const getError = field => errors[field]?.message || '';

  const submitHandler = async (formData) => {
    let target;

    // Use the 'Other' number field if it's been populated,
    // otherwise transform 'Goal amount' button text value to a number
    if (formData.fundraising_target_pounds) {
      target = formData.fundraising_target_pounds;
    } else {
      const removePounds = formData.fundraising_target_text.slice(1);
      const removeThousands = removePounds.replace(/,/g, '');
      target = parseInt(removeThousands, 10);
    }

    try {
      doScroll();
      setIsRegistering(true);

      const uuid = getUuid();
      const campaignCode = process.env.GATSBY_CAMPAIGN;

      const data = {
        uuid,
        campaign_code: campaignCode,
        ...formData,
        target_pounds: target
      };

      const reCaptchaToken = await executeRecaptcha(RECAPTCHA_ACTION);

      const { claimUrl } = await registerPage(data, reCaptchaToken);

      if (!claimUrl) {
        setHasFatalError(true);
        doScroll();
        return;
      }

      trackSignupAndRedirect(uuid, partnerName, data.company, claimUrl);

      // Display a manual redirect link after some seconds, just in case.
      setTimeout(() => {
        setManualRedirectUrl(claimUrl);
      }, 5000);
    } catch {
      setHasFatalError(true);
      doScroll();
    }
  };

  // Store and use this global value, since the Corporate
  // FSU doesn't have a 'campaign code' field itself
  const thisCampaign = process.env.GATSBY_CAMPAIGN;

  const formRef = useRef();

  return (
    <Layout>
      {/* todo: add SEO props */}
      <SEO
        title={`${partner.label} fundraiser registration | Comic Relief`}
        description={`${partner.label} fundraiser registration Comic Relief`}
      />
      <Wrapper ref={formRef} data-campaign={thisCampaign} className="fsu-wrapper--corp">
        <SingleMessage {...singleMessageProps[partnerName]} />
        <ProgressBar formRef={formRef} />
        <FormContainer css={{ position: 'relative' }}>
          <div
            css={{ position: 'absolute', top: '-75px' }}
            ref={scrollAnchorRef}
          />
          <ErrorBoundary
            showError={hasFatalError}
            errorMessage={fatalErrorMessage}
          >
            {isRegistering ? (
              <IsRegistering
                manualRedirectUrl={manualRedirectUrl}
                thankYouCopy={THANKYOU_COPY[partnerName]}
              />
            ) : (
              <form
                onSubmit={handleSubmit(submitHandler)}
                noValidate
                autoComplete="off"
              >
                <TwoColumnsSwappedOnDesktop>
                  <Logos companies={partner.companies} />
                  <div>
                    <Fieldset legend="Your Contact Details">
                      <Grid columns={1} mobileColumns={1}>
                        <Input
                          {...register(FIELDS.FIRST_NAME)}
                          type="text"
                          name={FIELDS.FIRST_NAME}
                          label="First Name"
                          id={FIELDS.FIRST_NAME}
                          errorMsg={getError(FIELDS.FIRST_NAME)}
                          autoComplete="off"
                        />
                        <Input
                          {...register(FIELDS.LAST_NAME)}
                          type="text"
                          name={FIELDS.LAST_NAME}
                          label="Last Name"
                          id={FIELDS.LAST_NAME}
                          errorMsg={getError(FIELDS.LAST_NAME)}
                          autoComplete="off"
                        />
                        <Input
                          {...register(FIELDS.EMAIL)}
                          type="text"
                          name={FIELDS.EMAIL}
                          label="Email"
                          id={FIELDS.EMAIL}
                          errorMsg={getError(FIELDS.EMAIL)}
                          autoComplete="off"
                          data-test="email-input"
                        />
                        <Input
                          {...register(FIELDS.CONFIRM_EMAIL)}
                          type="email"
                          name={FIELDS.CONFIRM_EMAIL}
                          label="Confirm email address"
                          id={FIELDS.CONFIRM_EMAIL}
                          errorMsg={getError(FIELDS.CONFIRM_EMAIL)}
                          autoComplete="off"
                          data-test="confirm-email-input"
                        />
                      </Grid>
                    </Fieldset>
                    <Fieldset legend={partner.store_label || 'Your Store'}>
                      <Grid columns={1} mobileColumns={1}>
                        <Select
                          {...register('company')}
                          name="company"
                          id="company"
                          label={partner.store_select || 'Select your company'}
                          options={companyOptions}
                          errorMsg={getError('company')}
                        />
                        <Input
                          {...register('store_id')}
                          type="text"
                          name="store_id"
                          label={partner.store_id || 'Store ID or department'}
                          id="store_id"
                          errorMsg={getError('store_id')}
                          autoComplete="off"
                        />
                      </Grid>
                    </Fieldset>
                    <Fieldset legend="Your JustGiving Page">
                      <Grid columns={1} mobileColumns={1}>
                        <div>
                          <Input
                            css={{ marginBottom: spacing('sm') }}
                            {...register('page_title')}
                            type="text"
                            name="page_title"
                            label="Name your fundraising page"
                            id="page_title"
                            errorMsg={getError('page_title')}
                            autoComplete="off"
                          />
                          <FieldHint>
                            This name will be the title of your JustGiving page
                          </FieldHint>
                        </div>
                        <div>
                          <TextArea
                            css={{ marginBottom: spacing('sm') }}
                            {...register('page_story')}
                            type="textarea"
                            name="page_story"
                            label="Summarise your page: edit away!"
                            id="page_story"
                            errorMsg={getError('page_story')}
                            autoComplete="off"
                            rows={7}
                            defaultValue={DEFAULT_SUMMARY_COPY[partnerName]}
                          />
                          <FieldHint css={{ marginTop: spacing('sm') }}>
                            This description will appear on your JustGiving page
                          </FieldHint>
                        </div>
                        <GiftAid
                          register={register}
                          errorMessage={getError('accept_gift_aid')}
                        />
                      </Grid>
                    </Fieldset>
                  </div>
                </TwoColumnsSwappedOnDesktop>
                <Fieldset
                  legend="What's Your Fundraising Goal?"
                  description="Please enter the goal set by your store or team."
                >
                  <Target
                    register={register}
                    watch={watch}
                    setValue={setValue}
                    getError={getError}
                    first="£500"
                    second="£1,000"
                    third="£3,000"
                  />
                </Fieldset>
                <SubmitContainer css={{ justifyContent: 'flex-start' }}>
                  <ButtonWithStates
                    disabled={
                      methods.formState.isSubmitting
                      || !methods.formState.isValid
                    }
                    loading={methods.formState.isSubmitting}
                    loadingText="Submitting..."
                    type="submit"
                  >
                    Create my page
                  </ButtonWithStates>
                </SubmitContainer>
              </form>
            )}
            <Confetti trigger={isRegistering} />
            <PrivacyNotice />
          </ErrorBoundary>
        </FormContainer>
      </Wrapper>
    </Layout>
  );
};

FsuCorporatePartner.propTypes = {
  pageContext: PropTypes.shape({
    partner: PropTypes.string.isRequired
  }).isRequired
};

export default withRecaptcha(FsuCorporatePartner);
