import React from 'react';
import PropTypes from 'prop-types';
import singleMessage from '../SingleMessage/SingleMessage';
import SingleMessageDs from '../SingleMessageDs/SingleMessageDs';
import PartnersList from '../PartnersList/PartnersList';
import PeopleList from '../PeopleList/PeopleList';
import Cards from '../Cards/Cards';
import CardsDs from '../CardsDs/CardsDs';
import JokeGenerator from '../JokeGenerator/JokeGenerator';
import BigRedButtonChallenge from '../BigRedButtonChallenge/BigRedButtonChallenge';
import Boxes from '../Boxes/Boxes';
import Teasers from '../Teasers/Teasers';
import IframeBlock from '../IframeBlock/IframeBlock';
import VideoBanner from '../VideoBanner/VideoBanner';
import NewsBlock from '../NewsBlock/NewsBlock';
import Membership from '../Membership/Membership';
import Donate from '../Donate/Donate';
import DescriptorBlock from '../DescriptorBlock/DescriptorBlock';
import Promo from '../Promo/Promo';
import Heading from '../Heading/Heading';
import Banner from '../Banner/Banner';
import BiteSize from '../BiteSize/BiteSize';
import ParagraphHTML from '../ParagraphHTML/ParagraphHTML';
import ImpactSlider from '../ImpactSlider/ImpactSlider';
import ParagraphMap from '../ParagraphMap/ParagraphMap';
import WYMDCarousel from '../WYMDCarousel/WYMDCarousel';

const Paragraphs = (props) => {
  const {
    paragraphs,
    partners = null,
    people = null,
    articles = null,
    moneyBuys = null,
    descriptors = null
  } = props;
  return paragraphs.map((paragraph, index) => {
    const key = `paragraph-${paragraph.id ? paragraph.id : index}`;
    const category = paragraph.featuredCategory && paragraph.featuredCategory.title;

    /* eslint no-underscore-dangle: 0 */
    switch (paragraph.__typename) {
      case 'ContentfulParagraphDonate': {
        return <Donate donate={paragraph} moneyBuys={moneyBuys} key={key} />;
      }
      case 'ContentfulParagraphMembership': {
        return <Membership membershipInfo={paragraph} key={key} />;
      }
      case 'ContentfulPartnersList': {
        return <PartnersList partnersList={partners} key={key} />;
      }
      case 'ContentfulPeopleList': {
        return <PeopleList people={people} key={key} />;
      }
      case 'ContentfulParagraphCards': {
        return <Cards data={paragraph} key={key} />;
      }
      case 'ContentfulParagraphCardsDs': {
        return <CardsDs data={paragraph} key={key} />;
      }
      case 'ContentfulParagraphJokeGenerator': {
        return <JokeGenerator data={paragraph} key={key} />;
      }
      case 'ContentfulParagraphBigRedButtonChallenge': {
        return <BigRedButtonChallenge data={paragraph} key={key} />;
      }
      case 'ContentfulParagraphBoxes': {
        return <Boxes data={paragraph} key={key} />;
      }
      case 'ContentfulParagraphTeasers': {
        return <Teasers data={paragraph} key={key} />;
      }
      case 'ContentfulParagraphIframe': {
        return <IframeBlock data={paragraph} key={key} />;
      }
      case 'ContentfulParagraphVideoBanner': {
        return <VideoBanner data={paragraph} key={key} />;
      }
      case 'ContentfulParagraphDescriptors': {
        return <DescriptorBlock data={descriptors} key={key} />;
      }
      case 'ContentfulSingleMessageDs': {
        return <SingleMessageDs data={paragraph} key={key} />;
      }
      case 'ContentfulPromo': {
        return <Promo data={paragraph} key={key} />;
      }
      case 'ContentfulHeading': {
        return <Heading data={paragraph} key={key} />;
      }
      case 'ContentfulBanner': {
        return <Banner data={paragraph} key={key} />;
      }
      case 'ContentfulBiteSize': {
        return <BiteSize data={paragraph} key={key} />;
      }
      case 'ContentfulParagraphFeaturedNews': {
        return (
          <NewsBlock
            key={key}
            data={articles}
            backgroundColor={paragraph.backgroundColour}
            paragraphTitle={paragraph.title}
            hideParagraphTitle={paragraph.hideTitle}
            category={category}
            isParagraphFeaturedNews
          />
        );
      }
      case 'ContentfulParagraphHtml': {
        return <ParagraphHTML data={paragraph} key={key} />;
      }
      case 'ContentfulImpactSlider': {
        return <ImpactSlider data={paragraph} key={key} />;
      }
      case 'ContentfulParagraphMap': {
        return <ParagraphMap data={paragraph} key={key} />;
      }
      case 'ContentfulWhatYourMoneyDoesCarousel': {
        return <WYMDCarousel data={paragraph} key={key} />;
      }
      default: {
        return singleMessage(paragraph);
      }
    }
  });
};

export default Paragraphs;

Paragraphs.propTypes = {
  paragraphs: PropTypes.oneOfType([PropTypes.shape, PropTypes.array])
    .isRequired,
  partners: PropTypes.oneOfType([PropTypes.shape, PropTypes.array]),
  people: PropTypes.oneOfType([PropTypes.shape, PropTypes.array]),
  articles: PropTypes.oneOfType([PropTypes.shape, PropTypes.array]),
  moneyBuys: PropTypes.oneOfType([PropTypes.shape, PropTypes.array]),
  descriptors: PropTypes.shape({
    group: PropTypes.arrayOf(
      PropTypes.shape({
        fieldVale: PropTypes.string,
        totalCount: PropTypes.number
      })
    ),
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        node: PropTypes.shape({
          category: PropTypes.arrayOf(PropTypes.string),
          date: PropTypes.string,
          id: PropTypes.string,
          // richText json format, maybe we need to create a model for this format
          descriptions: PropTypes.objectOf(PropTypes.shape),
          tags: PropTypes.arrayOf(PropTypes.string),
          title: PropTypes.string
        })
      })
    )
  })
};
