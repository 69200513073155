import styled from 'styled-components';
import { spacing, Text } from '@comicrelief/component-library';

import Grid from '../../components/Grid/Grid';

export const FieldHint = styled(Text).attrs({ size: 'xs', color: 'grey_label' })`
  margin-left: ${spacing('sm')};
`;

export const ErrorMessageContainer = styled.div`
  max-width: 640px;
  margin: auto;
  text-align: center;
  padding: ${spacing('l')} 0;
`;

export const TwoColumnsSwappedOnDesktop = styled(Grid).attrs({
  columns: 2,
  mobileColumns: 1
})`
  @media ${({ theme }) => theme.allBreakpoints('M')} {
    > div:nth-of-type(1) {
      order: 2;
    }

    > div:nth-of-type(2) {
      order: 1;
    }
  }
`;
