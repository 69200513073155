import PropTypes from 'prop-types';

const MpValidationOption = PropTypes.shape({
  yes: PropTypes.bool.isRequired,
  hideInput: PropTypes.bool.isRequired,
  disableOption: PropTypes.bool.isRequired
});

export const MpValidationOptionsPropTypes = PropTypes.shape({
  mp_permissionEmail: MpValidationOption,
  mp_permissionPhone: MpValidationOption,
  mp_permissionPost: MpValidationOption,
  mp_permissionSMS: MpValidationOption
});

export const BannerSingleMessagePropTypes = PropTypes.shape({
  __typename: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  copyFirst: PropTypes.bool.isRequired,
  centredText: PropTypes.bool,
  fullImage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  youTubeVideoId: PropTypes.string,
  landscapeVideo: PropTypes.string,
  image: PropTypes.shape({
    description: PropTypes.string.isRequired,
    fluid: PropTypes.shape({
      srcSet: PropTypes.string.isRequired
    }),
    fallback: PropTypes.shape({
      src: PropTypes.string.isRequired
    }),
    placeHolder: PropTypes.shape({
      src: PropTypes.string.isRequired
    }),
    seoImage: PropTypes.shape({
      src: PropTypes.string.isRequired
    })
  }),
  secondaryImage: PropTypes.shape({
    src: PropTypes.string
  }),
  verticalHeight: PropTypes.bool.isRequired,
  label: PropTypes.string,
  text: PropTypes.shape({
    raw: PropTypes.string.isRequired,
    // Ignoring the no `any` here as a reference could theoretically be anything.
    /* eslint-disable-next-line  */
    references: PropTypes.arrayOf(PropTypes.any).isRequired,
  }),
  backgroundColour: PropTypes.string.isRequired
});

export const ContentfulImagePropTypes = PropTypes.shape({
  file: PropTypes.shape({
    url: PropTypes.string.isRequired
  })
});

export const ContentTextAreaPropTypes = PropTypes.shape({
  raw: PropTypes.string.isRequired
});
