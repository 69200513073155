import React, {
  useState, useRef, Component, useEffect
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { zIndex } from '@comicrelief/component-library';

const ScrollingProgressBar = styled.div`
  position: fixed;
  ${zIndex('medium')};
  top: 0;
  right: 0;
  left: 0;
  height: 15px;
  pointer-events: none;
  background-image: linear-gradient(to right,
    ${({ theme }) => theme.color('red')} 0% ${({ scroll }) => scroll}%,
    transparent ${({ scroll }) => scroll}% 100%
  );
`;

const ProgressBar = ({ formRef }) => {
  const [scroll, setScroll] = useState(100);
  const ticking = useRef(false);

  const updateProgressBar = () => {
    if (!ticking.current) {
      const windowHeight = formRef.current.offsetHeight - window.innerHeight;

      window.requestAnimationFrame(() => {
        setScroll(Math.floor((window.pageYOffset / windowHeight) * 100));
        ticking.current = false;
      });
    }
    ticking.current = true;
  };

  useEffect(() => {
    document.addEventListener('scroll', updateProgressBar);
    updateProgressBar();
    return () => document.removeEventListener('scroll', updateProgressBar);
  }, []);

  return (
    <>
      <ScrollingProgressBar scroll={scroll} />
    </>
  );
};

ProgressBar.propTypes = {
  formRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Component) })
  ]).isRequired
};

export default ProgressBar;
