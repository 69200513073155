import React from 'react';
import PropTypes from 'prop-types';
import { Text, ErrorText, spacing } from '@comicrelief/component-library';

const GiftAid = ({ register, errorMessage = '' }) => (
  <>
    <Text weight="bold" color="grey_label" tag="p" style={{ marginBottom: spacing('sm') }}>
      Gift Aid
    </Text>
    {[
      {
        id: 'gift-aid-yes',
        value: true,
        label: 'Yes, my page can claim Gift Aid',
        defaultChecked: true
      },
      {
        id: 'gift-aid-no',
        value: false,
        label: 'No, my page isn\'t eligible'
      }
    ].map(option => (
      <label
        key={option.id}
        htmlFor={option.id}
        css={{
          display: 'block',
          cursor: 'pointer',
          marginBottom: spacing('xsm')
        }}
      >
        <input
          id={option.id}
          type="radio"
          name="accept_gift_aid"
          value={option.value}
          defaultChecked={option.defaultChecked}
          {...register('accept_gift_aid')}
        />
        <Text css={{ marginLeft: spacing('xsm') }}>{option.label}</Text>
      </label>
    ))}
    {errorMessage ? <ErrorText weight="bold">{errorMessage}</ErrorText> : ''}
  </>
);

GiftAid.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  register: PropTypes.func.isRequired,
  errorMessage: PropTypes.string
};

export default GiftAid;
