import transformMarketingPref from '../MarketingPrefs/_utils';

/**
 * @param supporterFields
 * @param mpFields
 * @returns {{}}
 */
const formatMarketingPreferences = (userFields, mpFields) => ({
  firstname: userFields.firstName,
  lastname: userFields.lastName,
  permissionEmail: transformMarketingPref(mpFields.mp_permissionEmail),
  email: userFields.email,
  permissionPost: transformMarketingPref(mpFields.mp_permissionPost),
  address1: userFields.address1,
  // As Prize form only has address1 field
  address2: null,
  address3: null,
  town: userFields.town,
  postcode: userFields.postcode,
  country: userFields.country,
  permissionPhone: transformMarketingPref(mpFields.mp_permissionPhone),
  phone: userFields.mobile, // No Phone field, so passing Mobile, as per requirements
  permissionSMS: transformMarketingPref(mpFields.mp_permissionSMS),
  mobile: userFields.mobile,
  campaign: userFields.campaign,
  transSource: 'PRIZE',
  transSourceUrl: window.location.href,
  transType: 'prefs'
});

export { formatMarketingPreferences, transformMarketingPref };
